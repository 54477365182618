<template>
  <div>
    <UIPageLoader />
    <slot/>
    <Toaster rich-colors />
  </div>
</template>
<script setup lang="ts">
import {Toaster} from "vue-sonner";
useColorMode();
</script>